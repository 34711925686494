export const PRODUCT_CODES = {
  ethernet: "DL_Ethernet_1Gig_Internet_[1_Month_Recurring_69.00]",
  fiber: "DL_Fiber_1Gig_Internet_[1_Month_Recurring_69.00]"
};

//Ethernet Pricing
export const BOINGO_ETHERNET_AMOUNT = 69;
export const BOINGO_ETHERNET_DISCOUNTED_AMOUNT_OFF = 15;
export const BOINGO_ETHERNET_DISCOUNTED_PRICE = 54;

//VenueID
export const AFMTHM_VENUE_ID = "4596416";
export const ARSIMN_VENUE_ID = "4476516";
export const ARRUDR_VENUE_ID = "4476517";
export const AFEGLN_VENUE_ID = "4476519";
export const ARJOHN_VENUE_ID = "2340457";
export const ARBELV_VENUE_ID = "4476358";

// Mountain Home Promo
export const AFMTHM_PROMO_CODE = "MTHM15";
const AFMTHM_HEADER_TEXT = "Introducing the amazing new internet service now available at Mountain Home!";
const AFMTHM_FOOTER_TEXT = "* Must have continuous service for 30 days to receive gift card.";

// Define the common data for all venues that will be used in VENUE_DATA
const COMMON_VENUE_DATA = {
  HEADER_TEXT: "Introducing the amazing new internet service exclusively in the dorms and barracks.",
  PLAN_NAME: "1 gigabit",
  PROMO_TITLE: "Get $15 off your first 3 months",
  AMAZON_OFFER: "and get a $50 Amazon Gift Card after 30 days*",
  LIST_TEXT: [
    "Symmetrical download and upload speeds up to 1 gigabit",
    "No data caps or throttling",
    "Use your own equipment",
    "Instant on",
  ],
};

export const VENUE_DATA = {
  ...COMMON_VENUE_DATA,
  AFMTHM: {
    venue_id: AFMTHM_VENUE_ID,
    product_code: PRODUCT_CODES.ethernet,
    plan_name: COMMON_VENUE_DATA.PLAN_NAME,
    plan_price: BOINGO_ETHERNET_AMOUNT,
    promo: null,
    module_content: {
      header_text: AFMTHM_HEADER_TEXT,
      amazon_offer: null,
      amazon_offer_footer: null,
      list_text: COMMON_VENUE_DATA.LIST_TEXT,
    },
  },
  ARSIMN: {
    venue_id: ARSIMN_VENUE_ID,
    product_code: PRODUCT_CODES.ethernet,
    plan_name: COMMON_VENUE_DATA.PLAN_NAME,
    plan_price: BOINGO_ETHERNET_AMOUNT,
    promo: null,
    module_content: {
      header_text: COMMON_VENUE_DATA.HEADER_TEXT,
      amazon_offer: null,
      amazon_offer_footer: null,
      list_text: COMMON_VENUE_DATA.LIST_TEXT,
    },
  },
  ARRUDR: {
    venue_id: ARRUDR_VENUE_ID,
    product_code: PRODUCT_CODES.ethernet,
    plan_name: COMMON_VENUE_DATA.PLAN_NAME,
    plan_price: BOINGO_ETHERNET_AMOUNT,
    promo: null,
    module_content: {
      header_text: COMMON_VENUE_DATA.HEADER_TEXT,
      amazon_offer: null,
      amazon_offer_footer: null,
      list_text: COMMON_VENUE_DATA.LIST_TEXT,
    },
  },
  AFEGLN: {
    venue_id: AFEGLN_VENUE_ID,
    product_code: PRODUCT_CODES.ethernet,
    plan_name: COMMON_VENUE_DATA.PLAN_NAME,
    plan_price: BOINGO_ETHERNET_AMOUNT,
    promo: null,
    module_content: {
      header_text: COMMON_VENUE_DATA.HEADER_TEXT,
      amazon_offer: null,
      amazon_offer_footer: null,
      list_text: COMMON_VENUE_DATA.LIST_TEXT,
    },
  },
  ARBELV: {
    venue_id: ARBELV_VENUE_ID,
    product_code: PRODUCT_CODES.fiber,
    plan_name: COMMON_VENUE_DATA.PLAN_NAME,
    plan_price: BOINGO_ETHERNET_AMOUNT,
    promo: null,
    module_content: {
      header_text: COMMON_VENUE_DATA.HEADER_TEXT,
      amazon_offer: null,
      amazon_offer_footer: null,
      list_text: COMMON_VENUE_DATA.LIST_TEXT,
    },
  },
  ARJOHN: {
    venue_id: ARJOHN_VENUE_ID,
    product_code: PRODUCT_CODES.fiber,
    plan_name: COMMON_VENUE_DATA.PLAN_NAME,
    plan_price: BOINGO_ETHERNET_AMOUNT,
    promo: null,
    module_content: {
      header_text: COMMON_VENUE_DATA.HEADER_TEXT,
      amazon_offer: null,
      amazon_offer_footer: null,
      list_text: COMMON_VENUE_DATA.LIST_TEXT,
    },
  },
};
